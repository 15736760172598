<template>
  <div class="content">
    <div class="list">
      <p class="title">活动时间</p>
      <p>每周一0点至每周日24点</p>
      <p class="title">活动对象</p>
      <p>参与“赚钱”频道的所有用户</p>
      <p class="title">活动内容</p>
      <p>
        活动期间，在“赚钱”频道内赚取金额（仅计算高额奖励和充值奖励）达到指定任务要求，即可领取额外现金奖励
      </p>
      <p class="title">领奖方式</p>
      <p>
        活动期间，达到任务要求即可在页面领取奖励；达到多个任务要求，即可领取多次奖励；当周奖励领取截止至下周一24点
      </p>
      <img
        src="https://ppyos.xijiuyou.com/202202/task-table.png"
        alt=""
      />
      <p class="title">当周起始任务规则</p>
      <p>
        1、当周起始任务，根据上一周累计赚取金额（仅计算高额奖励和充值奖励）判断，如50元≤上周累计赚取＜100元，则本周起始任务从“第3级任务”赚取达到100元开始；
        <br />
        2、第1级和第2级任务，90天内只能领取3次；即如果90天内已经领取过3次“第2级任务”奖励，则即使上一周累计赚取＜50元，起始任务也只能是“第3级任务”；<br />
        3、白银会员起始任务不低于第4级，黄金会员起始任务不低于第5级，钻石会员起始任务不低于第6级；以当周0点会员等级为准；<br />
        4、如上周累计赚取≥10000元，则本周起始任务即为“第9级任务”赚取达到10000元；<br />
        5、当周任务从每周一0点开启，达到任务要求，即可领取奖励
      </p>
      <p class="title">举例说明</p>
      <p>
        会员A在本周一0点时是黄金会员，上周累计赚取152元，则本周起始任务为“第5级任务”赚取达到500元；<br />
        在周二时，会员A掉级了，变成了白银会员，但本周起始任务不变，仍然是“第5级任务”；<br />
        当会员A在本周累计赚取了2385元时，他可以直接领取“第5-7级任务”的任务奖励，共计110元。
      </p>
      <p class="shuom">
        特别说明：如活动期间出现恶意损害本平台或其他用户利益的行为，本平台有权限制奖励发放，扣除已发放的奖励等
      </p>
    </div>
    <img
      class="bottom"
      src="https://ppyos.xijiuyou.com/202202/flower-banner-black.png"
      alt=""
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: #83ae67;
  padding: 12px;
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .list {
    width: 100%;
    background: #ffffff;
    box-shadow: inset 0px 2px 0px 0px #ffffff;
    border-radius: 15px;
    padding: 1px 20px 18px;
    .title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;
      color: #444444;
      line-height: 30px;
      margin-top: 16px;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      line-height: 25px;
    }
    img {
      width: 100%;
      margin: 15px auto;
    }
    .shuom {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #444444;
      line-height: 25px;
      margin-top: 16px;
      margin-bottom: 90px;
      text-decoration:underline;
    }
  }
}
</style>